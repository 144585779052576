<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('installment.installment_pay') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <!--                    <router-link :to="'/installment/installment/view/'+idInstallment" class="btn btn-light font-weight-bolder">-->
          <!--                        <v-icon>mdi-chevron-left</v-icon>-->
          <!--                        {{ $t('back') }}-->
          <!--                    </router-link>-->
          <!--end::Button-->
        </div>
      </div>

      <div class="card-body p-0">
        <b-card-body>
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.payment_method') }}</label>
              <select name="" id="payment_method" v-model="data.payment_method" class="custom-select"
                      :class="validation && validation.payment_method ? 'is-invalid' : ''">
                <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.payment_method"
                    class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.amount') }}<span class="text-danger">*</span></label>
              <input type="number" :value="data.amount" readonly class="form-control"
                     :class="validation && validation.amount ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.amount[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.payment_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.payment_date" class="form-control"
                     :class="validation && validation.payment_date ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.payment_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_date[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.payment_status') }}</label>
              <select name="" id="status" v-model="data.status" class="custom-select"
                      :class="validation && validation.status ? 'is-invalid' : ''">
                <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.collected_by') }}<span class="text-danger">*</span></label>
              <!-- <select name="" id="collected_by" v-model="data.collected_user_id" class="custom-select" :class="validation && validation.collected_user_id ? 'is-invalid' : ''">
                  <option v-for="row in users" :value="row.id" :key="row.id">{{ row.full_name }}</option>
              </select> -->
              <div class="input-group mb-3">
                <multiselect v-model="collected_user"
                             :placeholder="$t('installment.collected_by')"
                             :class="validation && validation.collected_user_id ? 'is-invalid' : ''"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus"
                                                                                            style="padding: 0"></i></a>
                </div>
              </div>
              <span v-if="validation && validation.collected_user_id"
                    class="fv-plugins-message-container invalid-feedback">
                                {{ validation.collected_user_id[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.ref_no') }}</label>
              <input type="text" v-model="data.ref_no" class="form-control"
                     :class="validation && validation.ref_no ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.ref_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.ref_no[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.payment_details') }}</label>
              <textarea v-model="data.payment_details" class="form-control"
                        :class="validation && validation.payment_details ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.payment_details"
                    class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_details[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.receipt_notes') }}</label>
              <textarea v-model="data.receipt_noes" class="form-control"
                        :class="validation && validation.receipt_noes ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.receipt_noes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.receipt_noes[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.attachment') }}</label>
              <div class="form-group">
                <upload-file
                    @file="listenerAttachment"
                    :inner-id="'attachment'"
                    :placeholder="$t('installment.attachment')"
                    :upload="dir_upload"
                    :start-link="'base'"
                    v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                </upload-file>
              </div>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('installment.treasury') }}<span class="text-danger">*</span></label>
              <!-- <select name="" id="treasury_id" v-model="data.treasury_id" class="custom-select" :class="validation && validation.treasury_id ? 'is-invalid' : ''">
                  <option v-for="row in treasuries" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select> -->
              <div class="input-group mb-3">
                <multiselect v-model="treasury"
                             :placeholder="$t('installment.treasury')"
                             :class="validation && validation.treasury_id ? 'is-invalid' : ''"
                             label="name"
                             track-by="id"
                             :options="treasuries"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus"
                                                                                            style="padding: 0"></i></a>
                </div>
              </div>
              <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.treasury_id[0] }}
                            </span>
            </div>
          </div>
        </b-card-body>


      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-6">
            <button type="button" :disabled="isDisabled" class="btn btn-primary mr-2" @click="save">{{
                $t('save')
              }}
            </button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-installment-pay",
  data() {
    return {
      mainRoute: 'installmentmanagement/payments',
      mainRouteDependency: 'base/dependency',
      mainRouteInstallmentDetails: 'installmentmanagement/installment/installment-details',

      dir_upload: 'installments',

      // idEdit: this.$route.params.id ? this.$route.params.id : null,
      idEdit: this.$route.params.details_id ? this.$route.params.details_id : null,
      idDetails: this.$route.params.details_id ? this.$route.params.details_id : null,
      idInstallment: this.$route.params.installment_id ? this.$route.params.installment_id : null,


      data: {
        installment_details_id: this.$route.params.details_id ? this.$route.params.details_id : null,
        amount: null,
        payment_date: null,
        ref_no: null,
        attachment: null,
        payment_details: null,
        receipt_noes: null,
        treasury_id: null,
        collected_user_id: null,
        status: 1,
        payment_method: 0,
      },

      isEditing: false,
      isDisabled: false,
      reloadUploadAttachment: true,
      validation: null,
      attachment_url: null,

      collected_user: [],
      user: null,
      payment_methods: [],
      treasuries: [],
      status_list: [],
      users: [],
      treasury: null,
    };
  },
  watch: {
    collected_user: function (val) {
      if (val) {
        this.data.collected_user_id = val.id;
      }
    },
    treasury: function (val) {
      if (val) {
        this.data.treasury_id = val.id;
      }
    },
  },

  methods: {
    save() {
      if (this.isEditing) {
        return false;
      }
      this.create();

      // if (this.isEditing) {
      //     this.update();
      // } else {
      //     this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        installment_id: this.idInstallment,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/installment/installment/view/' + this.idInstallment);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        installment_id: this.idInstallment,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/installment/installment/view/' + this.idInstallment);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        if (response.data.data && response.data.data.installment_details_id) {
          if (response.data.data.status == 2) {
            this.isEditing = true;
            this.isDisabled = true;
          } else {
            this.isEditing = false;
          }
          this.data.installment_details_id = response.data.data.installment_details_id;
          this.data.amount = response.data.data.amount;
          this.data.payment_date = response.data.data.payment_date;
          this.data.ref_no = response.data.data.ref_no;
          this.data.attachment = response.data.data.attachment;
          this.data.payment_details = response.data.data.payment_details;
          this.data.receipt_noes = response.data.data.receipt_noes;
          this.data.treasury_id = response.data.data.treasury_id;
          this.data.collected_user_id = response.data.data.collected_user_id;
          this.collected_user = response.data.data.collected_user;
          this.treasury = response.data.data.treasury;
          this.data.status = response.data.data.status;
          this.data.payment_method = response.data.data.payment_method;
          this.attachment_url = response.data.data.attachment_url;
        }

        this.reloadUploadAttachment = true;
      });
    },
    getInstallmentDetailsData() {
      ApiService.get(this.mainRouteInstallmentDetails + '/' + this.idDetails).then((response) => {
        this.data.amount = response.data.data.installment_amount_per_one;
        this.data.payment_date = response.data.data.installment_due_date;

      });
    },

    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      } else {
        this.users = [];
      }
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
        ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
          this.treasuries = response.data.data;
        });
      
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/payment_status").then((response) => {
        this.status_list = response.data.data;
      });
    },

  },

  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pay_installment")}]);
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.installment"),
      route: '/installment/installment'
    }, {
      title: this.$t('view'),
      route: '/installment/installment/view/' + this.idInstallment
    }, {title: this.$t("MENU.pay_installment")}]);
    this.getPaymentMethods();
    this.getTreasuries();
    this.getStatusList();

    if (this.idDetails) {
      this.getInstallmentDetailsData();
    }
    if (this.idEdit) {
      this.getData();
    }
    // else {
    //     this.data.payment_date = new Date().toISOString().slice(0, 10);
    // }

  },
};
</script>


